import { useFlag } from '@config/site/app'
import { Flags } from '@config/site/app/flags'
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import { Button } from 'carbonarc-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ExportModal } from './ExportModal'

export type CheckboxOption = {
    id: string
    label: string
    disabled?: boolean
    checked?: boolean
}

interface ExportButtonProps {
    pageId: string
    isLoading?: boolean
    options: CheckboxOption[]
    size?: 'sm' | 'md'
    multipleArtistsSelected?: boolean
}

export function ExportButton(props: ExportButtonProps) {
    const enabled = useFlag(Flags.ENABLE_EXPORT)
    const { pageId, isLoading = false, options, size = 'md', multipleArtistsSelected = false } = props
    const { t } = useTranslation()
    const [showExportModal, setShowExportModal] = useState(false)
  
    if (!enabled) return null
    return (
      <>
        <Button
          size={size}
          disabled={isLoading}
          variant="outline"
          className="bg-white dark:bg-transparent dark:border-gray-800 dark:text-gray-300 group-[.pdf-report]:hidden"
          onClick={() => setShowExportModal(true)}
        >
          <ArrowUpTrayIcon className="w-5 h-5 mr-2 text-gray-400" />
          {t('export.title')}
        </Button>
  
        {showExportModal && (
          <ExportModal 
            show={showExportModal} 
            setShow={setShowExportModal} 
            pageId={pageId} 
            options={options}
            multipleArtistsSelected={multipleArtistsSelected}
          />
        )}
      </>
    )
}
